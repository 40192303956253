import React, { useState, useEffect } from 'react'
import styles from './DiscountBanner.module.css'
import { Button } from 'react-bootstrap'
import { checkAccountAge, isSameDate } from 'utils/general'

const DiscountBanner = ({ onUpgrade, userProfile }) => {
  const [show, setShow] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  const shouldShowDiscountBanner = () => {
    const  { created, productId } = userProfile;
    const trialAccount = !productId;
    const showValidForAccount = checkAccountAge(created);
    const lastShownDateStr = localStorage.getItem('nextShownDate');

    !trialAccount && lastShownDateStr && localStorage.removeItem('nextShownDate')
  
    if (userProfile?.email && showValidForAccount && trialAccount) {
      const today = new Date();
      let lastShownDate = null;
      let nextDate = null;
      let daysToAdd = Math.floor(Math.random() * 4) + 2;
     
      if (lastShownDateStr) {
        lastShownDate = new Date(JSON.parse(lastShownDateStr));
      }

      if (!lastShownDate) {  
        nextDate = new Date(today);
        nextDate.setDate(today.getDate() + daysToAdd); 
        setShowBanner(true)
        localStorage.setItem('nextShownDate', JSON.stringify(nextDate));
      } else if (isSameDate(lastShownDate, today)) {
          setShowBanner(true)
          let tempDate = new Date(lastShownDate);
          tempDate.setDate(lastShownDate.getDate() + daysToAdd);
          nextDate = tempDate;
          localStorage.setItem('nextShownDate', JSON.stringify(nextDate));
      } else {  
        setShowBanner(false)
      }
    }
  }

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setShow(true)
    }, 2000) // Delay for 2 seconds
    return () => clearTimeout(delayTimer)
  }, [])

  useEffect(() => {
    shouldShowDiscountBanner()
  }, [userProfile?.email])

  return (
    <>
      {show && showBanner && (
        <div className={`${styles.page} ${styles['slide-fwd-center']}`}>
          <div className={styles.container}>
            <div className={styles.content}>
              <span className={styles.title}>✨ Limited Offer</span>
              <span className={styles.description}>Save 10% when you upgrade to any subscription plan</span>
            </div>
            <Button onClick={onUpgrade} className={styles.btn} variant="outline-light">
              Claim offer
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
export default DiscountBanner
