import React from 'react'
import { isDevSite, isPreviewSite, isDevOrPreviewSite } from './utils/general'
import { imageMimeTypes, docMimeTypes, pptMimeTypes, excelMimeTypes } from './utils/file'

export const DOMAIN_SUFFIX = isDevSite() ? '.tiiny.co.uk' : '.tiiny.site'

export const TLDS = {
  TIINY_SITE: '.tiiny.site',
  TIINY_CO: '.tiiny.co',
  TIINY_CO_UK: '.tiiny.co.uk',
  TIINY_IO: '.tiiny.io',
  TIINY_HOST: '.tiiny.host',
  TIINY_PREVIEW_CO_UK: '.preview.tiiny.co.uk'
}

export const DEFAULT_TLDS = Object.values(TLDS)

export const TLD_S3_PATH_CONFIG = {
  [TLDS.TIINY_SITE]: ({ subdomain }) => subdomain,
  [TLDS.TIINY_CO]: ({ subdomain, tld }) => `${subdomain}${tld}`,
  [TLDS.TIINY_CO_UK]: ({ subdomain, tld }) => `${subdomain}${tld}`
}

export const FILE_TYPE = {
  PDF: 'PDF',
  HTML: 'HTML'
}

export const SITE_UPLOAD_TEXT = 'UPLOAD_TEXT'

// Analytics
export const SOURCE_HOMEPAGE = 'SOURCE_HOMEPAGE'
export const SOURCE_LOGIN = 'SOURCE_LOGIN_PAGE'
export const SOURCE_PRICING = 'SOURCE_PRICING_PAGE'
export const SOURCE_LOGGED_IN = 'SOURCE_LOGGED_IN'

// errors
export const errors = {
  DOMAIN_EXISTS: 'DOMAIN_EXISTS',
  NOT_OWNER: 'NOT_OWNER',
  NO_INDEX_FILE: 'NO_INDEX_FILE',
  EXCEEDS_FILE_SIZE: 'EXCEEDS_FILE_SIZE',
  EXCEEDS_SITE_LIMIT: 'EXCEEDS_SITE_LIMIT',
  MALICIOUS_CODE: 'MALICIOUS_CODE',
  SITE_ANALYTICS_DOES_NOT_EXISTS: 'SITE_ANALYTICS_DOES_NOT_EXISTS',
  INVALID_EMAIL: 'INVALID_EMAIL',
  ACCOUNT_NOT_EXISTS: 'ACCOUNT_NOT_EXISTS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  USER_EXISTS: 'USER_EXISTS',
  USER_TRIAL_EXPIRED: 'USER_TRIAL_EXPIRED',
  USER_UNVERIFIED: 'USER_UNVERIFIED',
  NO_CONTENT: 'NO_CONTENT',
  NO_FILE_FOUND: 'NO_FILE_FOUND',
  NO_LINK_FOUND: 'NO_LINK_FOUND',
  TOKEN_INVALID: 'TOKEN_INVALID',
  NO_PARENT_ACCOUNT: 'NO_PARENT_ACCOUNT',
  PASSWORD_ERROR: 'PASSWORD_ERROR',
  WAITING: 'WAITING',
  SSL_ERROR: 'SSL_ERROR',
  DNS_CONFIG_ERROR: 'DNS_CONFIG_ERROR',
  NO_CUSTOMER_ID: 'NO_CUSTOMER_ID',
  NO_SUBDOMAIN: 'NO_SUBDOMAIN',
  WASMER_DEPLOY_ERROR: 'WASMER_DEPLOY_ERROR',
  WASMER_DELETE_ERROR: 'WASMER_DELETE_ERROR',
  IP_BLOCK_ERROR: 'IP_BLOCK_ERROR',
  BANNED_SITE: 'BANNED_SITE',
  INVALID_AUTH_TOKEN: 'INVALID_AUTH_TOKEN',
  STRIPE_SUBSCRIPTION_CHECK_ERROR: 'STRIPE_SUBSCRIPTION_CHECK_ERROR',
  STRIPE_EMAIL_CHECK_ERROR: 'STRIPE_EMAIL_CHECK_ERROR',
  PADDLE_SUBSCRIPTION_CHECK_ERROR: 'PADDLE_SUBSCRIPTION_CHECK_ERROR',
  PADDLE_EMAIL_CHECK_ERROR: 'PADDLE_EMAIL_CHECK_ERROR',
  S3_ERROR: 'S3_ERROR',
  DYNAMO_QUERY_ERROR: 'DYNAMO_QUERY_ERROR',
  DYNAMO_NO_CONTENT: 'DYNAMO_NO_CONTENT',
  INVALID_UPDATE_CODE: 'INVALID_UPDATE_CODE',
  BAD_CAPTCHA: 'BAD_CAPTCHA',
  NO_CAPTCHA: 'NO_CAPTCHA',
  BAD_WEBHOOK_KEY: 'BAD_WEBHOOK_KEY',
  FORBIDDEN: 'FORBIDDEN',
  DOMAIN_DOES_NOT_EXIST: 'DOMAIN_DOES_NOT_EXIST',
  DOMAIN_IS_NOT_PROTECTED: 'DOMAIN_IS_NOT_PROTECTED',
  INVALID_DOMAIN: 'INVALID_DOMAIN',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  NO_ACTIVE_SUBSCRIPTION: 'NO_ACTIVE_SUBSCRIPTION',
  EMAIL_ALREADY_SUBSCRIBED: 'EMAIL_ALREADY_SUBSCRIBED',
  FAILED_TO_SUBSCRIBE_EMAIL: 'FAILED_TO_SUBSCRIBE_EMAIL',
  INVALID_ZIP_FILE: 'INVALID_ZIP_FILE',
  PLAN_NOT_FOUND: 'PLAN_NOT_FOUND',
  UPGRADE_PLAN_ERROR: 'UPGRADE_PLAN_ERROR',
  INVALID_COUPON: 'INVALID_COUPON',
  FAILED_TO_CONVERT_IMAGE_TO_PNG: 'FAILED_TO_CONVERT_IMAGE_TO_PNG',
  FAILED_TO_CONVERT_FILE_TO_HTML: 'FAILED_TO_CONVERT_FILE_TO_HTML',
  FAILED_TO_CONVERT_FILE_TO_PDF: 'FAILED_TO_CONVERT_FILE_TO_PDF',
  S3_UPLOAD_ERROR: 'S3_UPLOAD_ERROR',
  UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE'
}

export const ERROR_MSGS = {
  [errors.INVALID_EMAIL]: 'Please enter a genuine email address',
  [errors.INVALID_UPDATE_CODE]: 'Invalid update code',
  [errors.NOT_OWNER]: "Sorry, that link-name is taken",
  [errors.NO_INDEX_FILE]: "Your zip doesn't contain an index.html file",
  [errors.DOMAIN_EXISTS]: 'Sorry, that link-name is taken',
  [errors.USER_EXISTS]: 'User exists, please login to your account',
  [errors.USER_TRIAL_EXPIRED]: 'Sorry, your trial has expired. Please upgrade.',
  [errors.USER_UNVERIFIED]: "Please verify your account, we've sent you an email",
  [errors.MALICIOUS_CODE]:
    'We\'re unable to host this type of content <br />on a <b>free account</b>.<br/><br/><a href="https://tiiny.host/pricing"><b><u>Upgrade your account</u></b></a>',
  [errors.EXCEEDS_FILE_SIZE]: 'The file size exceeds the maximum allowed limit',
  [errors.EXCEEDS_SITE_LIMIT]: 'You have reached the maximum number of sites allowed',
  [errors.SITE_ANALYTICS_DOES_NOT_EXISTS]: 'Site analytics data does not exist',
  [errors.ACCOUNT_NOT_EXISTS]: 'This account does not exist',
  [errors.UNAUTHORIZED]: 'You are not authorized to perform this action',
  [errors.NO_CONTENT]: 'No content found',
  [errors.NO_FILE_FOUND]: 'The requested file was not found',
  [errors.NO_LINK_FOUND]: 'The requested link was not found',
  [errors.TOKEN_INVALID]: 'The provided token is invalid',
  [errors.NO_PARENT_ACCOUNT]: 'No parent account found',
  [errors.PASSWORD_ERROR]: 'Incorrect password',
  [errors.WAITING]: 'Please wait while we process your request',
  [errors.SSL_ERROR]: 'There was an error with the SSL certificate',
  [errors.DNS_CONFIG_ERROR]: 'There was an error with the DNS configuration',
  [errors.NO_CUSTOMER_ID]: 'No customer ID found',
  [errors.NO_SUBDOMAIN]: 'No subdomain specified',
  [errors.WASMER_DEPLOY_ERROR]: 'Error deploying site',
  [errors.WASMER_DELETE_ERROR]: 'Error deleting site',
  [errors.IP_BLOCK_ERROR]: '',
  [errors.BANNED_SITE]: '',
  [errors.INVALID_AUTH_TOKEN]: 'Invalid authentication token',
  [errors.STRIPE_SUBSCRIPTION_CHECK_ERROR]: 'Error checking Stripe subscription',
  [errors.STRIPE_EMAIL_CHECK_ERROR]: 'Error checking Stripe email',
  [errors.PADDLE_SUBSCRIPTION_CHECK_ERROR]: 'Error checking Paddle subscription',
  [errors.PADDLE_EMAIL_CHECK_ERROR]: 'Error checking Paddle email',
  [errors.S3_ERROR]: 'Error with service',
  [errors.DYNAMO_QUERY_ERROR]: 'Query error',
  [errors.DYNAMO_NO_CONTENT]: 'No content found',
  [errors.BAD_CAPTCHA]: 'Bad captcha, please refresh your page & try again',
  [errors.NO_CAPTCHA]: 'Captcha verification failed, please refresh your page & try again',
  [errors.BAD_WEBHOOK_KEY]: '',
  [errors.FORBIDDEN]: 'Forbidden',
  [errors.DOMAIN_DOES_NOT_EXIST]: 'Domain does not exist',
  [errors.DOMAIN_IS_NOT_PROTECTED]: 'Domain is not protected',
  [errors.INVALID_DOMAIN]: 'Invalid domain',
  [errors.EMAIL_REQUIRED]: 'Email is required',
  [errors.NO_ACTIVE_SUBSCRIPTION]: 'No active subscription found',
  [errors.EMAIL_ALREADY_SUBSCRIBED]: 'Email already subscribed to the list',
  [errors.FAILED_TO_SUBSCRIBE_EMAIL]: 'Failed to subscribe email to the list',
  [errors.INVALID_ZIP_FILE]: 'The zip file is invalid',
  [errors.PLAN_NOT_FOUND]: 'Customer does not have a plan',
  [errors.UPGRADE_PLAN_ERROR]: 'Error upgrading plan',
  [errors.INVALID_COUPON]: 'Provide a valid coupon',
  [errors.FAILED_TO_CONVERT_IMAGE_TO_PNG]: 'Failed to convert file',
  [errors.FAILED_TO_CONVERT_FILE_TO_HTML]: 'Failed to convert file',
  [errors.FAILED_TO_CONVERT_FILE_TO_PDF]: 'Failed to convert file',
  [errors.S3_UPLOAD_ERROR]: 'Error with service',
  [errors.UNSUPPORTED_FILE_TYPE]: 'Unsupported file type'
}

/* eslint-disable */
// prettier-ignore
export const REGEX_FULLDOMAIN = /^([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
// prettier-ignore
export const REGEX_SUBDOMAIN = /^(?!-)[A-Za-z0-9-]*[A-Za-z0-9]-?$/
// prettier-ignore
export const REGEX_DOMAIN = /^[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])*$/;
// prettier-ignore
export const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// prettier-ignore
export const REGEX_USERNAME = /^[a-zA-Z0-9_]{3,20}$/;
// prettier-ignore
export const REGEX_PASSWORD = /^[^:]+$/;
// prettier-ignore
export const REGEX_ERROR_PATH = /^\/.+$/;

export const DISALLOWED_EMAILS = ['hi2.in', 'mailinator.com']
/* eslint-disable */

export const MAX_FILE_SIZE = 25 // link preview max
export const MAX_PDF_SIZE = 25 // link preview max

export const MAX_FREE_FILE_SIZE = 3
export const MAX_FREE_PDF_SIZE = 0.5

const getBaseURL = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8000'
  } else if (isPreviewSite()) {
    return 'https://preview-api.tiiny.co.uk'
  } else if (isDevSite()) {
    return 'https://api.tiiny.co.uk'
  } else {
    return 'https://api.tiiny.host'
  }
}

export const API_ROOT = getBaseURL()

export const reCAPTCHA_CLIENT = '6LeMIfQUAAAAAGblGGmutcrS1gaG3YMyRPzkG5N7'

const getStripeKey = () => {
  if (isDevOrPreviewSite()) {
    return 'pk_test_51GyQOLKnX1nnNv6ISZ7YCm5nBySiWLHHcFIir2ALdrrARBkHnSwZe2CTbadw9pWLEBbLVncQOAsXh56RK8YNMRsD00QdWbapEb'
  } else {
    return 'pk_live_51GyQOLKnX1nnNv6I5F3a2LwxUfNq7kDmV4oNDqpXk1LGdgaKaiOelZsUt8I7SYqffFSkBeYkyMNU9kWBAo7vFqtx00Rcdt5Fcu'
  }
}

export const STRIPE_KEY = getStripeKey()

export const ARCHIVE_ENDPOINT = 'https://s3-eu-west-1.amazonaws.com/archive.tiiny.host'

export const LIFETIME_PRICE = 79

export const ENTRI_APP_ID = isDevSite() ? 'tiiny.host_dev' : 'tiiny.host'

export const PSPDFKIT_LICENSE_KEY =
  'Tk6emxoHPFuLnQnuOKXgYMuE_zUqdg24KHBbegiVIY7kNddDqd5miEJDk4wcW6NaHq1CV3AYgtrlRl6_Rl1_4-tMaB09CZjEqu73Y_os0tL2N_5HL5J7tKbdYshmNyx6nguBJyk8l02_CnZ8Mf0iBDp3TTfdb7Q-Fz5zbUAT7BgFrHBjrAA-bsrcoAifaqVCLKrEL1eNJlIyN1nMDA_ZL9ouBQFeF-Ykvl6oPbUUPyso2mrNZFlugCjFHR_f0ZWdq_rADyrKWIfuRPEHxrVKU6o6T2cg1fO2fGTukWq3mxsUZVCd8ux5Q5I16HgjJXcDFf7FPLpYNNMTrK8AS0VKjKCo8CTcwj3Bs7QIGOmVKzbV55-aj_X3s2I52huRaRJtri_xb6VEq2mbFysnpFsFM-3vtncXrhbRA6IY4Uk4RakzzErmojSL1pqwVv6el78mGP1GMW8S4g3NJspd5HtdAWxZb82BZzFka1vvoFKZez0ASXKnbUlXAVvhGNUs3KMfrBGYOqonFldI7QmkSFD3vtyLAcHZAXSa8p7HuvDauGk='

export const DROPZONE_ACCEPTED_FILES =
  'text/html, application/zip, application/x-zip-compressed, multipart/x-zip, application/pdf, .pdf, .php, text/php, ' +
  imageMimeTypes.join(', ') +
  ', ' +
  docMimeTypes.join(', ') +
  ', ' +
  pptMimeTypes.join(', ') +
  ', ' +
  excelMimeTypes.join(', ')

// Todo: remove this once new file formats are supported on homepage upload
export const DROPZONE_ACCEPTED_FILES_HOMEPAGE =
  'text/html, application/zip, application/x-zip-compressed, multipart/x-zip, application/pdf, .pdf, .php, text/php'