import { timeZones } from './timezone'
import { FREE_PLAN_PRICES } from './free'
import { TINY_PLAN_PRICES } from './tiny'
import { SOLO_PLAN_PRICES } from './solo'
import { PRO_PLAN_PRICES } from './pro'
import { labelToPlanMap } from "constants/plans";

export const availableCurrencies = [
  { symbol: '€', name: 'EUR', region: 'EU' },
  { symbol: '£', name: 'GBP', region: 'UK' },
  { symbol: '$', name: 'USD', region: 'US' },
  { symbol: '₹', name: 'INR', region: 'IND' },
];

// TODO: IND-EXP
export const availableCurrenciesSubset = [
  { symbol: '€', name: 'EUR', region: 'EU' },
  { symbol: '£', name: 'GBP', region: 'UK' },
  { symbol: '$', name: 'USD', region: 'US' },
];

export const getCurrencyNameBySymbol = (symbol) => {
  const currency = availableCurrencies.find(curr => curr.symbol === symbol);
  return currency ? currency.name : null;
}

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const getPlansPricesFromCurrency = (currencySymbol) => {
  const planPrices = {
    freePlan: {},
    tinyPlan: {},
    soloPlan: {},
    proPlan: {},
  };

  const getPricesForPlan = (planTypePrices) => {
    const prices = {};

    for (const planType in planTypePrices) {
      prices[planType] = {};

      for (const region in planTypePrices[planType]) {
        if (planTypePrices[planType][region].currency === currencySymbol) {
          prices[planType] = { ...planTypePrices[planType][region] };
        }
      }
    }

    return prices;
  };

  // Populate plan prices for each plan type
  planPrices.freePlan = getPricesForPlan(FREE_PLAN_PRICES);
  planPrices.tinyPlan = getPricesForPlan(TINY_PLAN_PRICES);
  planPrices.soloPlan = getPricesForPlan(SOLO_PLAN_PRICES);
  planPrices.proPlan = getPricesForPlan(PRO_PLAN_PRICES);

  return planPrices;
};

export const getAreaCurrency = (timeZone) => {
  // Find the region that matches the time zone
  for (const [region, timeZonesForRegion] of Object.entries(timeZones)) {
    if (timeZonesForRegion.includes(timeZone)) {
      // Find the currency symbol for the region
      const currency = availableCurrencies.find(curr => curr.region === region);
      return currency ? currency.symbol : '$'; // Default to USD if currency not found
    }
  }
  // Default to USD if timezone not found
  return '$';
};

export const getCurrencySymbolByCountryCode = (countryCode) => {
  const currency = availableCurrencies.find(curr => curr.name.toLowerCase() === countryCode?.toLowerCase());
  return currency?.symbol;
}

export const getCurrencyAndPrice = (currency) => {
  // check for existing user currency else use currency from timeZone
  const currencySymbol = currency ? currency : getAreaCurrency(TIMEZONE)
  const planPrices = getPlansPricesFromCurrency(currencySymbol);

  return planPrices;
};

export const getSelectedPlanAndPrices = (selectedPlan, prices) => {
  const planKey = labelToPlanMap[selectedPlan.label];
  const planPrice = prices[planKey];

  if (planPrice) {
    return {
      ...selectedPlan,
      prices: {
        monthly: {
          ...selectedPlan.prices.monthly,
          stripePriceId: planPrice.monthly?.stripePriceId || selectedPlan.prices.monthly?.stripePriceId,
          value: planPrice.monthly?.value || selectedPlan.prices.monthly?.value,
          currency: planPrice.monthly?.currency || selectedPlan.prices.monthly?.currency
        },
        yearly: {
          ...selectedPlan.prices.yearly,
          stripePriceId: planPrice.yearly?.stripePriceId || selectedPlan.prices.yearly?.stripePriceId,
          value: planPrice.yearly?.value || selectedPlan.prices.yearly?.value,
          currency: planPrice.yearly?.currency || selectedPlan.prices.yearly?.currency
        }
      }
    };
  }

  return selectedPlan;
};
