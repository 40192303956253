export const ERROR_CODE_MAP = {
  // Generic decline codes
  'generic_decline': 'Sorry, your card was declined. Please try a different card or contact your bank.',
  'do_not_honor': 'Your card has been declined. Please contact your card issuer for more details.',
  'transaction_not_allowed': 'Your card has been declined. Please contact your card issuer for more details.',

  // Insufficient funds
  'insufficient_funds': 'Your card has insufficient funds to complete the purchase. Please use an alternative payment method.',

  // Card issues
  'incorrect_number': 'Your card number is incorrect. Please check and try again.',
  'invalid_number': 'The card number is invalid. Please use a valid card.',
  'invalid_expiry_month': 'The card\'s expiration month is invalid. Please check and try again.',
  'invalid_expiry_year': 'The card\'s expiration year is invalid. Please check and try again.',
  'invalid_cvc': 'The card\'s security code is invalid. Please check and try again.',
  'expired_card': 'Your card has expired. Please use a different card.',
  'incorrect_cvc': 'The card\'s security code is incorrect. Please check and try again.',
  'card_declined': 'The card was declined. Please try a different card or contact your bank.',

  // Account status issues
  'lost_card': 'This card has been reported lost. Please use a different card or contact your bank.',
  'stolen_card': 'This card has been reported stolen. Please use a different card or contact your bank.',

  // Processing errors
  'processing_error': 'An error occurred while processing your card. Please try again later.',

  // 3D Secure issues
  'authentication_required': 'Your card requires additional authentication. Please complete the 3D Secure process.',

  // Fraud prevention
  'fraudulent': 'This transaction has been flagged as potentially fraudulent. Please contact your bank or use a different card.',

  // Currency issues
  'currency_not_supported': 'Your card does not support this currency. Please try a different card.',
};