import React, {useEffect, useRef, useState} from 'react';
import JSZip from 'jszip';
import axios from 'axios';
import * as yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Button, Dropdown, DropdownButton, FormControl, InputGroup} from 'react-bootstrap';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as Sentry from '@sentry/react';

import {
  API_ROOT,
  DOMAIN_SUFFIX,
  ERROR_MSGS,
  MAX_FILE_SIZE,
  MAX_PDF_SIZE,
  REGEX_DOMAIN,
  errors,
  DROPZONE_ACCEPTED_FILES,
  DROPZONE_ACCEPTED_FILES_HOMEPAGE
} from '../constants';
import {STAGE_SUCCESS, STAGE_SUCCESS_VERIFY} from '../Landing/Home/Home';
import SelectedFile, {isZipFile} from './SelectedFile';
import {
  createFileInput,
  getErrorMessage,
  isPDF,
  isValidFile,
  isValidFileSize,
  validateDisallowedEmails,
  zipMultipleFiles,
  isPhpFile,
  onUpgradePlan
} from '../utils/general';
import ContentSelector from './ContentSelector/ContentSelector';
import CustomTooltip from './CustomTooltip';
import {showUpgradeCardModal} from '../actions';
import FileListModal from 'components/FileListModal/FileListModal';
import SocialLogin from './SocialLogin/SocialLogin';
import {withRouter} from 'react-router';
import {setDraggedFile} from 'Manage/actions';
import { getPhpFiles } from 'utils/file'

import '../App.css';
import {getAddToCalHTML} from "../utils/AddToCal";

const params = new URLSearchParams(window.location.search);

const additionalEmailChecks = (value) => {
  if (value) {
    return validateDisallowedEmails(value.toLowerCase());
  }
};

const schema = yup.object({
  subdomain: yup.string().matches(REGEX_DOMAIN, "Oops, that's not a valid website name"),
  fileSelected: yup.mixed(),
  email: yup
    .string()
    .when('fileSelected', (f, schema) =>
      f && f.isTemplate
        ? schema
        : schema
          .email("Oops, that's not a valid email")
          .test('email', 'Please enter a real email address', additionalEmailChecks)
          .required('Please enter an email')
    )
});

const HomepageUpload = ({disabled, contentType, history, setRootState, googleReCaptchaProps}) => {
  const [showFileList, setShowFileList] = useState(false);
  const [htmlFiles, setHtmlFiles] = useState();
  const [phpFiles, setPhpFiles] = useState([])
  const [authToken, setAuthToken] = useState();
  const [domainSuffix, setDomainSuffix] = useState(DOMAIN_SUFFIX)
  const [isZipPhpFile, setIsZipPhpFile] = useState(false)
  const formRef = useRef();
  const draggedFile = useSelector((state) => state.manage.draggedFile);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('message', onMessageEvent);
    // on unmount
    return () => window.removeEventListener('message', onMessageEvent);
  }, []);

  useEffect(() => {
    const handleDraggedFilePhp = async () => {
      if (isZipFile(draggedFile?.type)) {
        await zipPhpFileUploaded(draggedFile)
      } else if(isPhpFile(draggedFile)){
        setDomainSuffix('.tiiny.io')
      }
    }
    handleDraggedFilePhp()
  }, [draggedFile])

  const zipPhpFileUploaded = async (file) => {
    const { containsPhpFile, phpFiles } = await getPhpFiles(file);
    if(containsPhpFile && phpFiles?.length > 0){
      setPhpFiles(phpFiles);
      setShowFileList(containsPhpFile)
    }
  }

  const onFileSelected = async (setFieldValue, files) => {
    let file = files[0] || draggedFile;
    if (file) {
      if (isValidFileSize(file)) {
        if (isZipFile(file.type)) {
          await zipPhpFileUploaded(file)
        } else if(isPhpFile(file)) {
          if (file.name !== 'index.php') {
            file = new File([file], 'index.php', { type: file.type, lastModified: file.lastModified })
          }
          setDomainSuffix('.tiiny.io')
        }
        setFieldValue('fileSelected', file);
        setFieldValue('files', []);
      } else {
        dispatch(
          showUpgradeCardModal({
            title: 'Upgrade to upload larger files',
            desc: `${isPDF(file) ? 'PDFs' : 'Files'} should be less than ${
              isPDF(file) ? MAX_PDF_SIZE : MAX_FILE_SIZE
            }MB on our free plan. Upgrade for more.`,
            cta: 'Upload larger files',
            onClick: file?.size ? () => onUpgradePlan({fileSize: file?.size}, dispatch) : null
          })
        );
      }
    } else {
      alert('We currently support html, pdf, php, and zip files.\n\nPlease Login or Sign up to upload images & documents.');
    }
  };

  const onIndexFileSelected = (file) => {
    setShowFileList(false);
    onSubmit(
      {
        ...formRef.current.values,
        indexFile: file
      },
      authToken
    );
  };

  const onTemplateSelected = (setFieldValue, value) => setFieldValue('fileSelected', value);

  const onSubmit = async ({subdomain, fileSelected, email, indexFile, files, isZipPhpFile}, authToken) => {
    const formData = new FormData();

    const template = fileSelected?.isTemplate;

    if (template) {
      formData.append('useTemplate', fileSelected.path);
    } else {

      if (files.length > 0) {
        formData.append('file', await zipMultipleFiles([...files, fileSelected]));
      } else {
        const fileToUpload = fileSelected ? fileSelected : draggedFile;
        formData.append('file', fileToUpload);
        indexFile && formData.append('indexFile', indexFile);
      }

      if (authToken) {
        formData.append('authToken', authToken);
      } else {
        formData.append('email', email?.toLowerCase());
      }
    }

    isZipPhpFile && formData.append('isZipPhpFile', isZipPhpFile)
    formData.append('domain', subdomain ? subdomain.toLowerCase() : '');
    formData.append('domainSuffix', domainSuffix);

    setRootState({showLoading: true});

    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('homepage');
    const headers = {
      'x-recaptcha': recaptchaValue
    };

    const endpoint = `${API_ROOT}/create-domain`;
    axios
      .post(endpoint, formData, {headers})
      .then((res) => {
        if (authToken) {
          history.push(`/free-welcome?id=${authToken}&trial=true`);
        } else {
          setRootState({
            showLoading: false,
            stage: template ? STAGE_SUCCESS : STAGE_SUCCESS_VERIFY,
            domain: res.data.created,
            recordedEmail: res.data.recordedEmail
          });
        }
      })
      .catch((err) => {
        const htmlFiles = err?.response?.data?.htmlFileList;
        const isValidResponse = err?.response?.data?.code === errors.NO_INDEX_FILE && htmlFiles?.length > 0;
        if (isValidResponse) {
          setHtmlFiles(htmlFiles);
          setShowFileList(isValidResponse);
        } else {
          setAuthToken(undefined);

          const errorMessage = getErrorMessage(err);
          if (errorMessage && errorMessage !== '') {
            setRootState({
              showLoading: false,
              showError: true,
              errorMessage
            });
          } else if (errorMessage && errorMessage === '') {
            setRootState({
              showLoading: false,
              showError: true,
              errorMessage: 'Oops, something went wrong'
            });
            console.error(err)
          } else {
            setRootState({
              showLoading: false,
              showError: true,
              errorMessage: 'Oops, something went wrong'
            });
            Sentry.captureException(err, {
            tags: {
                section: "homepage-upload-error",
              },
            });
          }
        }
      });
  };

  const openWindow = (url) => window.open(url, '_blank');

  const openOAuthWindow = (provider) => openWindow(`${API_ROOT}/oauth/${provider}?behavior=modal`);

  useEffect(() => {
    if (authToken) {
      onSubmit(formRef.current.values, authToken);
    }
  }, [authToken]);

  const onMessageEvent = (event) => {
    if (event.data.type === 'oauthCallback') {
      if (event.data.newAccount) {
        setAuthToken(event.data.token);
      } else {
        setRootState({
          showLoading: false,
          showError: true,
          errorMessage: ERROR_MSGS[errors.USER_EXISTS]
        });
      }
    }
  };

  async function zipTestHTML(setFieldValue) {
    const ref = params.get('ref')
    if (ref === 'test-html') {
      const retrievedHTML = localStorage.getItem('html-code');
      const retrievedCSS = localStorage.getItem('css-code');
      const retrievedJS = localStorage.getItem('js-code');

      if (retrievedHTML) {
        const zip = new JSZip();
        zip.folder('html')
          .file('index.html', retrievedHTML)
          .file('main.css', retrievedCSS)
          .file('main.js', retrievedJS);

        const content = await zip.generateAsync({type: 'blob'});
        let file = new File([content], 'html.zip', {
          type: 'application/zip',
          lastModified: new Date()
        });
        file.path = 'html.zip';
        setFieldValue('fileSelected', file);
        setFieldValue('files', []);

        // clean up
        localStorage.removeItem('html-code');
        localStorage.removeItem('css-code');
        localStorage.removeItem('js-code');

        return file;
      }

    } else if (ref === "invite") {
      const addToCalConfig = localStorage.getItem("addToCalConfig");
      localStorage.removeItem("addToCalConfig")

      const htmlContent = getAddToCalHTML(JSON.parse(addToCalConfig));
      const inviteFile = new File([htmlContent], 'invite.html', {
        type: 'text/html',
        lastModified: new Date(),
        path: "invite.html"
      });
      setFieldValue('fileSelected', inviteFile);

      return inviteFile;
    }
  }

  // Define a function to handle adding more files
  const addMoreFiles = (setFieldValue, files, fileSelected) => {
    const input = createFileInput();

    input.addEventListener("change", (event) => {
      const selectedFile = event.target.files[0];
      selectedFile.path = selectedFile.name;

      if (isValidFile(fileSelected, selectedFile, files)) {
        const updatedFiles = [...files, selectedFile];
        setFieldValue('files', updatedFiles);
      } else {
        dispatch(
          showUpgradeCardModal({
            title: 'Upgrade to upload larger files',
            desc: `${isPDF(selectedFile) ? 'PDFs' : 'Files'} should be less than ${
              isPDF(selectedFile) ? MAX_PDF_SIZE : MAX_FILE_SIZE
            }MB on our free plan. Upgrade for more.`,
            cta: 'Upload larger files',
            onClick: selectedFile?.size ? () => onUpgradePlan({fileSize: selectedFile?.size}, dispatch) : null
          })
        );
      }
    });

    input.click();
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        initialValues={{
          fileSelected: undefined,
          subdomain: undefined,
          email: undefined,
          files: [],
          indexFile: undefined,
          isZipPhpFile
        }}
        validateOnBlur
      >
        {({ handleSubmit, handleBlur, handleChange, values, setFieldValue, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <InputGroup className="input-domain">
              <FormControl
                className="tr-landing-domain-input"
                name="subdomain"
                type="text"
                placeholder="link-name"
                aria-label="Subdomain"
                value={values.subdomain}
                onChange={handleChange}
                disabled={disabled}
                onBlur={handleBlur}
                isInvalid={touched.subdomain && errors.subdomain}
              />
              <DropdownButton
                id="input-group-dropdown-2"
                variant="outline-primary"
                as={InputGroup.Append}
                title={domainSuffix}
              >
                <Dropdown.Item onClick={() => (window.location.hash = 'pricing')}>
                  <span style={{fontSize: '14px', color: '#333'}}>
                    Add custom domain <img alt="Pro" src="/assets/icons/pro-black.png" height="20"/>
                  </span>
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
            <ErrorMessage
              name="subdomain"
              render={() => (
                <div className="mt-1 validation-error text-center">
                  Please enter a valid website name.{' '}
                  <CustomTooltip label="Only letters, numbers & hyphens (-) are allowed. No spaces.">
                    <span className="link">
                      <u>Help</u>
                    </span>
                  </CustomTooltip>
                </div>
              )}
            />
            {['test-html', 'invite'].includes(params.get('ref')) || draggedFile || values.fileSelected ? (
              <>
                <div className={`${values.files.length > 0 ? 'file-list' : ''}`}>
                  <SelectedFile
                    file={values.fileSelected || draggedFile || (async () => await zipTestHTML(setFieldValue))()}
                    onRemove={() => {
                      setFieldValue('fileSelected', undefined);
                      setIsZipPhpFile(false)
                      dispatch(setDraggedFile(null));
                      setDomainSuffix(DOMAIN_SUFFIX)
                      params.delete('ref');
                      history.replace({
                        pathname: '/',
                      })
                    }}
                  />
                  {values.files.map((file, index) => (
                    <SelectedFile
                      key={index}
                      file={file}
                      onRemove={() => {
                        const updatedFiles = values.files.filter((f) => f.name !== file.name);
                        setFieldValue('files', updatedFiles)
                      }}
                    />
                  ))}
                </div>
                {!isZipFile(values.fileSelected?.type || draggedFile?.type) && !isPhpFile(values.fileSelected || draggedFile) && (
                  <Button
                    className="mb-3 mt-2 ms-auto d-block"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      addMoreFiles(setFieldValue, values.files, values.fileSelected) // Trigger file input click
                    }}
                  >
                    + Add more files
                  </Button>
                )}
                {!values.fileSelected?.isTemplate && !draggedFile?.isTemplate && (
                  <>
                    <div className="mb-3 mt-2">
                      <div className="bold font-16 color-purple-light">Please verify your email</div>
                      <span className="light-grey font-small">We need to verify your email to reduce spam</span>
                    </div>
                    <SocialLogin onClick={openOAuthWindow} className="height-50" disabled={errors.subdomain} />
                    <div className="separator mt-3 w-50 ms-auto me-auto">Or use a different email</div>
                    <div className="flex">
                      <div className="flex-2">
                        <FormControl
                          className="mt-3 tr-landing-email-input"
                          name="email"
                          type="text"
                          placeholder="Email"
                          aria-label="Email"
                          value={values.email}
                          onChange={handleChange}
                          disabled={disabled}
                        />
                        <ErrorMessage name="email" component="div" className="validation-error" />
                      </div>
                      <Button
                        type="submit"
                        className="flex-1 ms-2 mt-3 tr-landing-launch-site"
                        variant="outline-primary"
                      >
                        Continue
                      </Button>
                    </div>
                  </>
                )}
                {values.fileSelected?.isTemplate && (
                  <Button type="submit" className="action-btn w-100 tr-landing-launch-site mt-2" variant="purple">
                    Publish
                  </Button>
                )}
              </>
            ) : (
              <>
                <ContentSelector
                  className="tr-landing-upload-file-zone"
                  type={contentType}
                  onDrop={(file) => {
                    onFileSelected(setFieldValue, file)
                  }}
                  onTemplateSelected={(template) => onTemplateSelected(setFieldValue, template)}
                  // Todo: change this once new file formats are supported on homepage upload
                  acceptedFiles={DROPZONE_ACCEPTED_FILES_HOMEPAGE}
                />
                <ErrorMessage
                  component="div"
                  name="fileSelected"
                  render={() => (
                    <div className="mt-1 validation-error text-center">Please upload a zip or single file</div>
                  )}
                />
              </>
            )}
            {htmlFiles && htmlFiles.length > 0 && (
              <FileListModal
                show={showFileList}
                onHide={() => setShowFileList(false)}
                onFileSelected={onIndexFileSelected}
                filesList={htmlFiles}
                title="Which file should be your homepage?"
                subtitle="We've found multiple HTML files in your Zip"
              />
            )}
            {phpFiles?.length > 0 && (
              <FileListModal
                show={showFileList}
                onHide={() => setShowFileList(false)}
                onFileSelected={(file) => {
                  setFieldValue('indexFile', file)
                  setIsZipPhpFile(true)
                  setDomainSuffix('.tiiny.io')
                  setShowFileList(false)
                }}
                filesList={phpFiles}
                title="Which file should be your homepage?"
              />
            )}
          </form>
        )}
      </Formik>
    </>
  )
};

const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(undefined, mapDispatchToProps)(withRouter(withGoogleReCaptcha(HomepageUpload)));
