import React, { useState, useEffect } from 'react'
import SocialLogin from '../components/SocialLogin/SocialLogin'
import { API_ROOT, ERROR_MSGS, REGEX_EMAIL } from '../constants'
import { errors } from '../constants';
import { Button, Modal, Form, InputGroup, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { validateDisallowedEmails } from 'utils/general'
import axios from 'axios'
import { getErrorMessage } from 'utils/general';

const LOGGED_IN = 'LOGGED_IN'
const REQUIRE_EMAIL = 'REQUIRE_EMAIL'
const REQUIRE_EMAIL_PASSWORD = 'REQUIRE_EMAIL_PASSWORD'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_ERROR = 'LOGIN_ERROR'
const LOADING = 'LOADING'
const REQUIRE_UPGRADE = 'REQUIRE_UPGRADE'

const DEFAULT_STATE = {
  stage: REQUIRE_EMAIL,
  error: undefined
}

const SignUpPage = () => {
  const [currentState, setCurrentState] = useState(DEFAULT_STATE)
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const history = useHistory()
  const isLoginPage = window.location.pathname === '/login'

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setCurrentState({ stage: LOGGED_IN })
    }
  }, [])

  const onEmailChange = (e) => {
    const email = e.target.value
    const isValidEmail = REGEX_EMAIL.test(email.toLowerCase()) && validateDisallowedEmails(email.toLowerCase())
    setEmail(email)
    setIsValidEmail(isValidEmail)
  }

  const handleLogin = () => {
    const { stage } = currentState
    if (stage === LOGGED_IN) {
      history.push('/manage')
    } else if (stage === REQUIRE_EMAIL) {
      loginWithEmail()
    } else if (stage === LOGIN_SUCCESS || stage === LOGIN_ERROR) {
      retryLogin()
    } else if (stage === REQUIRE_UPGRADE) {
      history.push('/pricing')
    }
  }

  const retryLogin = () => {
    setCurrentState(DEFAULT_STATE)
  }

  const loginWithEmail = () => {
    setCurrentState({ stage: LOADING })

    const data = { email }

    const promoCode = localStorage.getItem('promoCode')
    if (promoCode) {
      data.promoCode = promoCode
    }

    axios
      .post(`${API_ROOT}/login`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        setCurrentState({ stage: LOGIN_SUCCESS })
      })
      .catch((err) => {
        if (err?.response?.data?.code === errors.USER_TRIAL_EXPIRED) {
          setCurrentState({ stage: REQUIRE_UPGRADE })
        } else if (err?.response?.data?.code === errors.INVALID_EMAIL) {
          setCurrentState({ stage: LOGIN_ERROR, error: errors.INVALID_EMAIL })
        } else {
          setCurrentState({ stage: LOGIN_ERROR })
        }
      })
  }

  const handleKeyDown = (e) => e.key === 'Enter' && isValidEmail && handleLogin()

  const onSignOut = () => {
    localStorage.removeItem('token')
    history.go(0)
  }

  const { stage, error } = currentState

  return (
    <div className="container">
      <h5 className="text-start">
        <b>{(isLoginPage ? 'Login or ' : '') + 'Sign up'}</b>
      </h5>
      <div className="text-start mt-3">
        {(isLoginPage ? 'Login or ' : '') +
          "Sign up with your favourite platform or we'll send you a one-time login link."}
      </div>
      <div className="text-start mt-2 grey">We're password-less.</div>
      <div className="box-100 p-3 login-box mt-4 border-light-grey">
        <h5 className="text-center mb-3">{(isLoginPage ? 'Login or ' : '') + 'Sign up'}</h5>
        {stage === LOGGED_IN && <div className="login-message">You're logged in</div>}
        {stage === REQUIRE_EMAIL && (
          <InputGroup className="input-group">
            <Form.Control
              onChange={onEmailChange}
              onKeyPress={handleKeyDown}
              placeholder="Email"
              aria-label="email"
              autoFocus={true}
              type="email"
            />
          </InputGroup>
        )}
        {stage === LOGIN_SUCCESS && (
          <div className="login-message">
            We've sent a login link to <b>{email}</b>
          </div>
        )}
        {stage === LOGIN_ERROR && (
          <div className="login-message">
            {getErrorMessage(error) || 'Sorry, unable to send you an email.'}
            <p className="link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
              <u>Contact support</u>
            </p>
          </div>
        )}
        {stage === REQUIRE_UPGRADE && <div className="login-message ps-3 pe-3">{ERROR_MSGS[errors.USER_TRIAL_EXPIRED]}</div>}
        {stage !== LOADING && (
          <Button
            className="action-btn w-100 mt-2"
            disabled={stage !== LOGGED_IN && !isValidEmail}
            onClick={handleLogin}
          >
            {stage === LOGGED_IN && 'Continue'}
            {stage === REQUIRE_EMAIL && 'Email Me Login Link'}
            {stage === REQUIRE_EMAIL_PASSWORD && 'Login'}
            {stage === LOGIN_SUCCESS && 'Try again'}
            {stage === LOGIN_ERROR && 'Try again'}
            {stage === REQUIRE_UPGRADE && 'Upgrade to Pro'}
          </Button>
        )}
        {stage === LOGGED_IN && (
          <div className="mt-2 font-small">
            or{' '}
            <span className="link" onClick={onSignOut}>
              Sign out
            </span>
          </div>
        )}
        {stage === LOADING && <div className="w-100 text-center"><Spinner className="mt-3 ms-auto mr-auto" animation="border" /></div>}
        {stage === REQUIRE_EMAIL && (
          <>
            <div className="separator mt-3 w-75 ms-auto me-auto">Or Sign up with</div>
            <SocialLogin className="mt-3" />
          </>
        )}
      </div>
      <div className="toc">
        By using our service you accept our{' '}
        <a href="https://tiiny.host/termsofservice.html" target="_blank">
          <u>terms & conditions</u>
        </a>{' '}
        &{' '}
        <a href="https://tiiny.host/privacypolicy.html" target="_blank">
          <u>privacy policy</u>
        </a>
      </div>
    </div>
  )
}

export default SignUpPage
