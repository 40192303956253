import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import { errors } from '../../constants';
import {API_ROOT, REGEX_EMAIL} from "../../constants";
import {getErrorMessage} from "../../utils/general";
import * as Sentry from "@sentry/react";

const SuccessBoxVerify = ({googleReCaptchaProps, domain, onClose, recordedEmail = false, setRootState}) => {
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [emailResponse, setEmailResponse] = useState({ emailSent: recordedEmail });
  const [showEmailInput, setShowEmailInput] = useState(false);

  const onKeep = async () => {
    const isValid = REGEX_EMAIL.test(email.toLowerCase()) &&
      email.toLowerCase().search('mailinator') === -1 &&
      !email.includes('+');

    if (!isValid) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    setIsFetching(true);

    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('homepage');
    const headers = {
      'x-recaptcha': recaptchaValue
    };

    axios
      .post(`${API_ROOT}/create-trial-account`,
        {
          subdomain: domain.split('.')[0],
          tld: `.${domain.split('.').slice(1).join('.')}`,
          email
        },
        {
          headers: {
            'Content-Type': 'application/json',
            ...headers
          }
        })
      .then(res => {
        setIsFetching(false);

        if (res.data.success) {
          setEmailResponse({
            emailSent: res.data.success
          })
        }
      })
      .catch((err) => {
        setIsFetching(false);
        setEmailResponse({
          notOwner: err.response.data.code === errors.NOT_OWNER
        })

        const errorMessage = getErrorMessage(err);
        if (errorMessage && errorMessage !== '') {
          setRootState({
            errorMessage: getErrorMessage(err)
          });
        } else if (errorMessage && errorMessage === '') {
          setRootState({
            errorMessage: 'Oops, something went wrong'
          });
          console.error(err)
        } else {
          setRootState({
            errorMessage: 'Oops, something went wrong'
          });
          Sentry.captureException(err, {
          tags: {
              section: "success-box-verify-error",
            },
          });
        }
      });
  };

  const handleResendClick = () => {
    setShowEmailInput(true);
  };

  const {emailSent, notOwner} = emailResponse;

  return <div className="success-box">
    <div onClick={onClose}>
      <img className="exit" src="delete.png" alt="quit"/>
    </div>
    <h3>BOOM!</h3>
    <img className="celebrate" src="celebrate.gif" alt="celebrate"/>
    <div>
      <h4 className="domain">
        {domain || "You link"}
      </h4>
      <h5>
        <>
          is nearly live...
        </>
      </h5>
    </div>
    <React.Fragment>
      {
        isFetching ?
          <Spinner className="mt-5" animation="border"/>
          :
          <div className="container-lock-site">
            {notOwner && <div>Sorry, that link name is taken</div>}
            <div>
              <div className="grey-background round-8 p-4">
                <div className="bold font-20">
                  <img alt="email" src="/assets/icons/mail.svg" height={30} className="me-2" />
                  We've sent you a verification email
                </div>
                <div className="font-18">Please check your inbox or spam folder</div>
                  <Form.Group className="mb-0">
                    <div className="flex justify-center ms-auto me-auto mt-4">
                      {showEmailInput ? (
                        <>
                          <Form.Control
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            aria-label="email"
                            type="email"
                          />
                          <Button
                            className="ms-2"
                            variant="primary"
                            onClick={onKeep}
                          >
                            Resend
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="outline-primary"
                          onClick={handleResendClick}
                        >
                          Resend email
                        </Button>
                      )}
                    </div>
                    {invalidEmail && <div className="validation-error">Sorry, that's not a valid email</div>}
                  </Form.Group>
              </div>
            </div>
          </div>
      }
    </React.Fragment>
  </div>
};

export default withGoogleReCaptcha(SuccessBoxVerify);