import React from 'react';
import { withRouter } from 'react-router';
import styles from './Footer.module.css';

const Footer = ({ history }) =>
    <div className={styles.pageFooter}>
      <div className={styles.page}>
        <div className={styles.footerContainer}>
          <div className={styles.footerInfo}>
            <div className={styles.logo}>
              <img
                className={styles.homeHeaderLogo}
                src="/assets/logo-white.png"
                alt="tiny.host"
                onClick={() => history.push('/')}
              />
              <div className={styles.about}>
                Tiiny Labs Ltd. is a company registered in England and Wales with company number 12977077
              </div>
            </div>
            <div className={styles.madeWith}>
              <div className="white">Built on </div>
              <img height={40} src="/assets/icons/aws.png" alt="Amazon Web Services" />
            </div>
            <div className={styles.socialLinks}>
              <a href="https://twitter.com/tiinyhost" target="_blank">
                <img alt="Twitter" src="/assets/icons/twitter.png" height="22" />
              </a>
              <a href="https://www.youtube.com/channel/UCmcesQ2I9hvEQqhI4CU6wBA" target="_blank">
                <img alt="YouTube" src="/assets/icons/youtube.png" height="25" />
              </a>
              <a href="https://www.linkedin.com/company/tiiny-host" target="_blank">
                <img alt="LinkedIn" src="/assets/icons/linkedin.png" height="16" />
              </a>
            </div>
          </div>
          <div className={styles.linksSection}>
            <div className={styles.linksCon}>
              <h3 className={styles.linkHeader}>Examples</h3>
              <ul className={styles.links}>
                <li onClick={() => history.push('/examples/sales-marketing')}>Sales & Marketing</li>
                <li onClick={() => history.push('/examples/designers-artists')}>Designers & Artists</li>
                <li onClick={() => history.push('/examples/developers')}>Developers</li>
                <li onClick={() => history.push('/examples/real-estate')}>Real Estate</li>
                <li onClick={() => history.push('/examples/recruitment')}>Recruitment</li>
                <li onClick={() => history.push('/examples/e-learning-publishing')}>E-Learning & Publishing</li>
                <li onClick={() => history.push('/examples/crypto')}>Crypto</li>
                <li onClick={() => history.push('/examples/students')}>Students</li>
              </ul>
            </div>
            <div className={styles.linksCon}>
              <h3 className={styles.linkHeader}>Use Cases</h3>
              <ul className={styles.links}>
                <li>
                  <a href="https://tiiny.host/host-html-file/">Host HTML file</a>
                </li>
                <li>
                  <a href="https://tiiny.host/web-hosting-free-sites/">Web hosting free sites</a>
                </li>
                <li>
                  <a href="https://tiiny.host/zip-site-web-hosting/">Zip file hosting</a>
                </li>
                <li>
                  <a href="https://tiiny.host/use-case/resume-link/">Resume link</a>
                </li>
                <li>
                  <a href="https://tiiny.host/pdf-upload/">PDF upload</a>
                </li>
                <li>
                  <a href="https://tiiny.host/use-case/pdf-to-qr-code/">PDF to QR Code</a>
                </li>
                <li>
                  <a href="https://tiiny.host/free-static-website-hosting/">Free static website hosting</a>
                </li>
                <li>
                  <a href="https://tiiny.host/web-hosting-for-small-business/">Web hosting for small business</a>
                </li>
                <li>
                  <a href="https://tiiny.host/web-hosting-for-students/">Web hosting for students</a>
                </li>
                <li>
                  <a href="https://tiiny.host/marzipano-vr-host/">Marzipano VR host</a>
                </li>
                <li>
                  <a href="https://tiiny.host/how-to-host-your-own-website/">How to host your own website</a>
                </li>
                <li><a href="https://tiiny.host/upload/">Upload →</a></li>
                <li><a href="https://tiiny.host/host/">Host →</a></li>
                <li><a href="https://tiiny.host/deploy/">Deploy →</a></li>
                <li><a href="https://tiiny.host/use-case/">Use cases →</a></li>
              </ul>
            </div>
            <div className={styles.linksCon}>
            <h3 className={styles.linkHeader}>Resources</h3>
              <ul className={styles.links}>
                <li><a href="https://helpdesk.tiiny.host/" target="_blank">Documentation</a></li>
                <li>
                  <a href="https://tiiny.host/test-html/">Test HTML</a>
                </li>
                <li>
                  <a href="https://tiiny.host/html-viewer/">HTML Viewer</a>
                </li>
                <li>
                  <a href="https://tiiny.host/html-validator/">HTML Validator</a>
                </li>
                <li>
                  <a href="https://tiiny.host/html-formatter/">HTML Formatter</a>
                </li>
                <li>
                  <a href="https://tiiny.host/invite/">Tiiny Invite</a>
                </li>
                <li><a href="https://tiiny.host/blog">Blog</a></li>
                <li onClick={() => window.$crisp.push(['do', 'chat:open'])}>Support Chat</li>
                <li onClick={() => history.push('/help')}>FAQs</li>
                <li onClick={() => history.push('/affiliates')}>Affiliate program</li>
                <li onClick={() => history.push('/report-site')}>Report abuse</li>
              </ul>
            </div>
            <div className={styles.linksCon}>
              <h3 className={styles.linkHeader}>Useful Links</h3>
              <ul className={styles.links}>
                <li onClick={() => history.push('/pricing')}>Pricing</li>
                <li onClick={() => history.push('/help')}>Contact</li>
                <li onClick={() => history.push('/login')}>Login / Sign Up</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.footerPrivacyContainer}>
          <div className={styles.privacy}>
            © Tiiny Host {new Date().getFullYear()}
          </div>
          <div className={styles.privacy}>
            <a href="https://tiiny.host/termsofservice.html" target="_blank">Terms & Conditions</a>
            <a href="https://tiiny.host/privacypolicy.html" target="_blank">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>

export default withRouter(Footer);
