import React, { useEffect, useState } from 'react'
import CreateUpdateSiteForm from './form/CreateUpdateSiteForm'
import { LoadingBox } from './components/LoadingBox'
import { useDispatch, useSelector } from 'react-redux'
import { getErrorMessage, getFullDomainValue, getTLDs, pluralize, onUpgradePlan } from 'utils/general'
import { Modal } from 'react-bootstrap'
import {
  fetchUserData,
  setAction,
  setDraggedFile,
  setPreviewData,
  setPreviewModal,
  setSiteType,
  showCreateSiteModal,
  toggleModal
} from 'Manage/actions'
import { createNewSite, getSite, updateSite } from './actions'
import { createNewSiteRequest, getUpdatedFields, updateSiteRequest, validate } from './validate'
import {
  ACTION_CREATE,
  ACTION_UPDATE,
  STATE_ERROR,
  STATE_LOADING,
  STATE_READY,
  STATE_SUCCESS,
  STATE_REQUESTED,
  STATE_PREVIEW
} from './interface'
import SuccessBox from './components/SuccessBox'
import { showUpgradeCardModal } from 'actions'
import { ErrorBox } from '../ErrorBox/ErrorBox'
import { getSimulationDuration } from './components/LoadingBox'
import { uploadedMoreThanOneProject, uploadedFileTooBig } from 'utils/sendy'
import * as Sentry from "@sentry/react";

const CreateUpdateSiteModal = ({ sites, disableProFeatures, liveSitesCount, executeRecaptcha }) => {
  const dispatch = useDispatch()

  const [isOnline, setIsOnline] = useState(false)
  const loadingSites = useSelector(state => state.manage.loadingSitesStore)

  /** V1 */
  const { action, userProfile, show, data, customDomains, draggedFile, siteType } = useSelector(
    ({ manage: { createSiteModal, userProfile, customDomains, draggedFile, siteType } }) => {
      return {
        action: createSiteModal.action || ACTION_CREATE,
        userProfile: userProfile,
        show: createSiteModal.visible,
        data: createSiteModal?.data || undefined,
        customDomains: customDomains.filter((c) => ['CONNECTED', 'VALIDATED'].includes(c.status.toUpperCase())),
        draggedFile: draggedFile,
        siteType: siteType
      }
    }
  )

  const modalValues = {
    action: action || ACTION_CREATE,
    data
  }

  /** Modal values V2 */
  const [pageState, setPageState] = useState(STATE_LOADING)
  const [fullDomain, setFullDomain] = useState('')
  const [site, setSite] = useState(undefined)
  const [files, setFiles] = useState([])
  const [newSite, setNewSite] = useState(undefined)
  const [currentFormValues, setCurrentFormValues] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [progress, setProgress] = useState(0)
  const [simulatedDurationProgress, setSimulatedDurationProgress] = useState(300)

  const tlds = getTLDs({ account: userProfile })

  const setModalValues = (mySite = undefined, siteFiles = [], myNewSite = undefined, state = STATE_READY) => {
    if (myNewSite) {
      dispatch(setAction(ACTION_UPDATE))
    }

    setSite(myNewSite || mySite)

    if (state === STATE_SUCCESS) {
      setNewSite(myNewSite || mySite)
    } else if (state === STATE_PREVIEW) {
      uploadedFileTooBig()
      dispatch(setPreviewModal(true))
      dispatch(setPreviewData(myNewSite))
      onClose()
    } else {
      setNewSite(myNewSite)
    }

    setFiles(siteFiles)
    setFullDomain(myNewSite?.subdomain || mySite?.subdomain || '')
    setCurrentFormValues(undefined)
    setPageState(state)

    setProgress(0)
    setSimulatedDurationProgress(300)
  }

  useEffect(() => {
    if (pageState !== STATE_SUCCESS && pageState !== STATE_REQUESTED && !isOnline) {
      const mySite = site || data
      setModalValues(mySite, undefined, undefined, STATE_READY)
    }
  }, [data, action])

  useEffect(() => {
    setPageState(STATE_LOADING)
    const fetchSite = async () => {
      try {
        const { link, siteFiles } = await getSite(action, fullDomain)

        if (link.linkStatus !== 'error') {
          setModalValues(link, siteFiles)
        } else {
          setPageState(STATE_ERROR)
        }
      } catch (error) {
        setPageState(STATE_ERROR)
        return
      }
      setPageState(STATE_READY)
    }

    if (action === ACTION_CREATE && pageState !== STATE_ERROR) {
      setModalValues()
    } else {
      if (pageState !== STATE_REQUESTED) fetchSite()
    }
  }, [fullDomain])

  useEffect(() => {
    const fetchSite = async () => {
      try {
        const { link, siteFiles } = await getSite(action, fullDomain)

        if (link.linkStatus === 'online') {
          setIsOnline(true)
        } else {
          setIsOnline(false)
        }

        if (link.linkStatus !== 'error') {
          setModalValues(link, siteFiles, undefined, STATE_SUCCESS)
        } else {
          setPageState(STATE_ERROR)
        }
      } catch (error) {
        setPageState(STATE_ERROR)
        return
      }
    }

    if (action === ACTION_CREATE && pageState !== STATE_ERROR) {
      setModalValues()
    } else {
      if (fullDomain && pageState !== STATE_REQUESTED) fetchSite()
    }
  }, [loadingSites])  

  /** Click modal actions : */
  const onClose = () => {
    setModalValues()
    dispatch(showCreateSiteModal(false))
    dispatch(toggleModal(false))
    dispatch(setDraggedFile(null))
    dispatch(setSiteType(null))
    setIsOnline(false)
  }

  const onReset = (mySite = undefined, myFiles = []) => {
    setModalValues(mySite, myFiles)
    if (mySite) {
      dispatch(setAction(ACTION_UPDATE))
    }
    dispatch(setDraggedFile(null))
    dispatch(setSiteType(null))
    setIsOnline(false)
  }

  const onRetry = () => {
    setPageState(STATE_READY)
  }

  const onSubmit = async (formValues, initValues) => {
    let myNewSite
    let myNewFiles

    /** only on update */
    const domainValuesToUpdate = getUpdatedFields(formValues.domainValues, initValues.domainValues)
    const toggleValuesToUpdate = getUpdatedFields(formValues.toggleValues, initValues.toggleValues)
    const uploadValuesToUpdate = getUpdatedFields(formValues.uploadValues, initValues.uploadValues)

    /** */

    const siteLimit = userProfile.siteLimit || 1

    if (action === ACTION_CREATE && siteLimit <= liveSitesCount) {
      uploadedMoreThanOneProject()
      dispatch(
        showUpgradeCardModal({
          title: 'Upgrade to upload more content',
          desc: `Your current plan only allows ${siteLimit} live ${pluralize(
            'project',
            'projects',
            siteLimit
          )}. Upload more on a different plan.`,
          cta: 'Create more projects',
          onClick: () => onUpgradePlan({projects: siteLimit}, dispatch)
        })
      )
      return
    }

    const uploadProgress = ({ loaded, total }, { size }) => {
      const currentProgress = Math.round(((100 * loaded) / total) * 0.5)
      setProgress(currentProgress)

      if (currentProgress === 50) {
        setSimulatedDurationProgress(getSimulationDuration(size))
      }
    }

    const executeRecaptchaWrapper = async () => {
      try {
        return await executeRecaptcha()
      } catch (error) {
        console.error(error)
        Sentry.captureException(error, {
          tags: {
            section: 'create-update-site-recaptcha-error'
          }
        })
        return null
      }
    }

    try {
      setPageState(STATE_LOADING)

      if (action === ACTION_UPDATE) {
        if (domainValuesToUpdate || toggleValuesToUpdate || uploadValuesToUpdate) {
          const data = await updateSite(
            getFullDomainValue(initValues.domainValues.subdomain, initValues.domainValues.domainSuffix),
            updateSiteRequest({ domainValuesToUpdate, toggleValuesToUpdate, uploadValuesToUpdate , siteType}),
            executeRecaptchaWrapper,
            uploadProgress
          )

          myNewSite = data.link
          myNewFiles = data.files
        }
      }

      if (action === ACTION_CREATE) {
        const data = await createNewSite(
          createNewSiteRequest({
            domainValues: formValues.domainValues,
            toggleValues: formValues.toggleValues,
            uploadValues: getUpdatedFields(formValues.uploadValues, initValues.uploadValues),
            siteType
          }),
          executeRecaptchaWrapper,
          uploadProgress
        )

        myNewSite = data.link
        myNewFiles = data.files || []
      }

      setPageState(STATE_REQUESTED)

      setModalValues(
        myNewSite || site,
        myNewFiles || files,
        myNewSite,
        myNewSite?.linkPreview ? STATE_PREVIEW : STATE_SUCCESS
      )
    } catch (error) {
      console.error(error)
      setCurrentFormValues(formValues)
      setPageState(STATE_ERROR)

      const errorMessage = getErrorMessage(error);
      if (errorMessage && errorMessage !== '') {
        setErrorMessage(getErrorMessage(error))
      } else if (errorMessage && errorMessage === '') {
        setErrorMessage('Oops, something went wrong')
        console.error(error)
      } else {
        setErrorMessage('Oops, something went wrong')
        Sentry.captureException(error, {
        tags: {
            section: "create-update-site-error",
          },
        });
      }
    } finally {
      dispatch(fetchUserData())
    }
  }
  /** */

  const formValues = {
    action,
    userProfile,
    site: site,
    files: files,
    customDomains,
    tlds,
    disableProFeatures,
    draggedFile,
    currentFormValues,
    siteType
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        if (pageState !== STATE_LOADING && pageState !== STATE_REQUESTED) {
          localStorage.removeItem('active_custom_domain')
          localStorage.removeItem('active_custom_subdomain')
          onClose()
        }
      }}
      {...modalValues}
    >
      <div>
        {(() => {
          switch (pageState) {
            case STATE_ERROR:
              return <ErrorBox onClick={onRetry} message={errorMessage || 'Oops, something went wrong'} />
            case STATE_LOADING || STATE_REQUESTED: {
              return (
                <LoadingBox
                  showProgressBar={progress > 0 && progress < 50}
                  showSimulationProgressBar={progress >= 50}
                  progress={progress}
                  simulatedDurationProgress={simulatedDurationProgress}
                  action={action}
                />
              )
            }
            case STATE_SUCCESS:
              return (
                <SuccessBox
                  userProfile={userProfile}
                  site={newSite}
                  files={files}
                  onClose={onClose}
                  onReset={onReset}
                  isOnline={isOnline}
                />
              )
            default:
              return (
                <div className="p-3">
                  <CreateUpdateSiteForm formValues={formValues} onSubmit={onSubmit} validate={validate} />
                </div>
              )
          }
        })()}
      </div>
    </Modal>
  )
}

export default CreateUpdateSiteModal
