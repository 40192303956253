import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch, connect } from 'react-redux';

import { setDraggedFile, showCreateSiteModal } from 'Manage/actions';
import {isPDF, isValidFileSize, pluralize, isPhpFile, onUpgradePlan} from 'utils/general';
import { PRO_MAX_FILE_SIZES } from 'constants/plans';
import Placeholder from './Placeholder';
import { useLocation } from 'react-router-dom';
import { showUpgradeCardModal } from 'actions';
import {MAX_FILE_SIZE, MAX_PDF_SIZE, DROPZONE_ACCEPTED_FILES} from "../../../constants";
import {ACTION_CREATE} from "../../CreateUpdateSiteModal/interface";

const DropzoneWrapper = ({ children, className, siteLimit, trialAccount, liveSites, updateModalOpen, acceptedFiles = DROPZONE_ACCEPTED_FILES }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const userProfile = useSelector((state) => state.manage.userProfile);

  const onDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onSiteAction = (modalAction, data) => {
    let modalData;
    if (data) {
      modalData = {
        ...data,
        domain: data.subdomain
      };
    }

    dispatch(showCreateSiteModal(true, modalData, modalAction));
  };

  const onCreateSite = (trialAccount, siteLimit) => {
    if (liveSites.length >= siteLimit) {
      dispatch(
      showUpgradeCardModal({
        title: 'Upgrade to upload more content',
        desc: `Your current plan only allows ${siteLimit} live ${pluralize('project', 'projects', siteLimit)}. Upload more on a different plan.`,
        cta: 'Host more sites',
        onClick: siteLimit ? () => onUpgradePlan({projects: siteLimit}, dispatch) : null
      }))
    } else {
      onSiteAction(ACTION_CREATE);
    }
  };

  const onDropRejected = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onFileDrop = useCallback(
    (acceptedFiles) => {
      const isTrial = !userProfile.productId;
      const maxSizeMB = userProfile.fileUploadLimit || PRO_MAX_FILE_SIZES[userProfile.productId];
      if (acceptedFiles.length) {
        let file = acceptedFiles[0];
        if (isValidFileSize(file, maxSizeMB)) {
          if(isPhpFile(file) && !userProfile?.email ) {
            if (file.name !== 'index.php') {
              file = new File([file], 'index.php', { type: file.type, lastModified: file.lastModified })
            }   
          }
          dispatch(setDraggedFile(file));
          setIsDragging(false);
          if (location.pathname === '/manage' && !updateModalOpen) {
            onCreateSite(trialAccount, siteLimit);
          }
        } else {
          if (!updateModalOpen) {
            dispatch(
              showUpgradeCardModal({
                title: 'Upgrade to upload larger files',
                desc: `Your current plan only allows you to upload ${isPDF(file) ? 'PDFs' : 'files'} less than ${
                  isPDF(file) && isTrial ? MAX_PDF_SIZE : (maxSizeMB || MAX_FILE_SIZE)}MB. Upload more on a different plan.`,
                cta: 'Upload larger files',
                onClick: file?.size ? () => onUpgradePlan({fileSize: file?.size}, dispatch) : null
              })
            );
          }
          setIsDragging(false);
        }
      } else {
        alert('We currently support html, pdf, php, and zip files.\n\nLogin or Sign up to also be able to upload most popular image, raw image, document, powerpoint, excel formats!');
        setIsDragging(false);
      }
    },
    [dispatch, location.pathname, onSiteAction, userProfile, updateModalOpen]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    onDragEnter,
    onDragLeave,
    onDrop: onFileDrop,
    onDropRejected,
    accept: acceptedFiles,
    maxFilesize: 0.5
  });

  return (
    <div className={`dropzone-wrapper ${className || ''}`}>
      {isDragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderStyle: 'dashed',
            borderWidth: 1,
            border: isDragReject ? '2F5233' : isDragAccept ? ' #ff5c5c' : 'rgba(0, 0, 0, 0.5)',
            backgroundColor: isDragReject
              ? 'rgba(195, 20, 50, 0.85)'
              : isDragAccept
              ? 'rgba(17, 153, 142, 0.85)'
              : 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            pointerEvents: 'none',
            transition: 'all 0.3s ease-in-out'
          }}
        >
          <Placeholder isDragReject={isDragReject} />
        </div>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile
});

export default connect(mapStateToProps, undefined)(DropzoneWrapper);
