import { isDevOrPreviewSite } from '../../utils/general'

const PRODUCT_ID_PROD = 'prod_OaDIHVQwiyvU0k'
const PRODUCT_ID_DEV = 'prod_OaDEo0F1lj38QD'

const PRICE_ID_YEARLY_PROD = 'price_1Nn2rtKnX1nnNv6IgIsA0Wxe'
const PRICE_ID_YEARLY_DEV = 'price_1Nn2r6KnX1nnNv6IQK8YG4sv'

const PRICE_ID_MONTHLY_PROD = 'price_1Nn2rtKnX1nnNv6Isc95ltdV'
const PRICE_ID_MONTHLY_DEV = 'price_1Nn2nnKnX1nnNv6IzoEC0ngV'

const PADDLE_PRICE_ID_YEARLY_PROD = 850135
const PADDLE_PRICE_ID_YEARLY_DEV = 64086

const PADDLE_PRICE_ID_MONTHLY_PROD = 850130
const PADDLE_PRICE_ID_MONTHLY_DEV = 64085

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD
const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY =
  isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD

const PRO_PLAN = {
  id: PRODUCT_ID,
  label: 'Tiny',
  prices: {
    yearly: { id: PRICE_ID_YEARLY, value: 60, paddleId: PADDLE_PRICE_ID_YEARLY },
    monthly: { id: PRICE_ID_MONTHLY, value: 9, paddleId: PADDLE_PRICE_ID_MONTHLY }
  },
  fileSizeLimit: 25,
  customDomainsLimit: 0,
  projectLimit: 1,
  visitorLimit: 10000,
  bandwidthLimit: 10,
  features: {}
}

export default PRO_PLAN
