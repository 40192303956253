import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import { isDevOrPreviewSite } from "../../utils/general";
import {fetchUserData, showUpgradeModal as showSubscribeModal} from "../../Manage/actions";
import {PSPDFKIT_LICENSE_KEY} from "../../constants";
import { updatePDF } from "services/update-site";

function PDFEditor({ document, dispatch, setIsPublishing, isTrial }) {
  let instance, PSPDFKit;
  const containerRef = useRef(null);

  const pushPDFUpdate = async (document, updatedDocumentData) => {
    setIsPublishing(true)
    const fileName = document.split('/').pop();
    const body = new FormData();
    const blob = new Blob([updatedDocumentData], { type: 'application/pdf' });
    body.append('pdf', blob, fileName);
    body.append('document', document);
    updatePDF(body)
    .then((res) => {
      alert("Document updated successfully")
      dispatch(fetchUserData())
    })
    .catch((err) => {
      alert("Sorry, an error occurred")
      console.error(err)
    }).finally(() => setIsPublishing(false));
  }

  useEffect(() => {
    const container = containerRef.current;
    (async function () {
      PSPDFKit = await import("pspdfkit");
      PSPDFKit.unload(container)

      instance = await PSPDFKit.load({
        container,
        document,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        toolbarItems: [
          ...PSPDFKit.defaultToolbarItems.filter((i) => !['export-pdf', 'print'].includes(i.type)),
          { type: "content-editor" }
        ],
        licenseKey: (isDevOrPreviewSite()) ? undefined : PSPDFKIT_LICENSE_KEY
      })

      instance.setViewState(v => v.set("showToolbar", !isTrial));
      instance.setViewState(v => v.set("interactionMode", PSPDFKit.InteractionMode.CONTENT_EDITOR));

      if (!isTrial) {
        // Add/remove publish button based on instance state
        instance.addEventListener("viewState.change", (viewState) => {
          const interactionMode = viewState.toJS().interactionMode;
        
          if (interactionMode !== PSPDFKit.InteractionMode.CONTENT_EDITOR) {
            instance.setToolbarItems((items) => {
              // Check if the publish button already exists
              if (!items.some(item => item.id === "publish-button")) {
                items.push({
                  type: "custom",
                  id: "publish-button",
                  title: "Publish",
                  onPress: async () => {
                    try {
                      const updatedDocumentData = await instance.exportPDF();
                      await pushPDFUpdate(document, updatedDocumentData);
                    } catch (error) {
                      console.error("Error during publishing:", error);
                      // alert("Sorry, an error occurred while publishing the document.");
                    }
                  },
                })
              }
              return items;
            })
          } else {
            instance.setToolbarItems((items) => {
              return items.filter((i) => i.id !== 'publish-button');
            })
          }
        });
      }
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, []);

  return (
    <div className="w-100 vh-80" ref={containerRef}/>
  );
}

function PDFEditorModal({document, show, onClose, dispatch, trial, onUpgrade}) {
  const [isPublishing, setIsPublishing] = useState();

  return <Modal
    aria-labelledby="contained-modal-title-vcenter"
    className="height-95"
    show={show}
    size="xl"
    centered
  >
    <div className="p-3 bg-purple text-white">
      <h1 className="font-20 d-inline">PDF Editor</h1>
      <button className="transparent-button float-right" onClick={onClose}>
        <img src="/assets/icons/close-white.svg" height={20} width={20} alt="close" />
      </button>
      {trial && <Button
        className="float-right me-3"
        variant="purple"
        size="sm"
        onClick={onUpgrade}
      >
        Upgrade to publish
      </Button>}
      {isPublishing && <Spinner className="mt-1 me-2 float-right" size="sm" animation="border" variant="light" />}
    </div>
    <PDFEditor
      document={document}
      dispatch={dispatch}
      setIsPublishing={setIsPublishing}
      isTrial={trial}
    />
  </Modal>
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(undefined, mapDispatchToProps)(PDFEditorModal);