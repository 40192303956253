import React, {useEffect, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import {PERIOD_MONTH, PERIOD_YEAR, PLANS} from "../../../constants/plans";
import {CURRENT_PLAN, DOWNGRADE, UPGRADE} from "../UpgradePayModal";
import {ALL_FEATURES} from "../../PricingGrid/PricingGrid";
import PlanSelectionDropdown from "./PlanSelectionDropdown";
import FeatureList from "./FeatureList";

const downGradeFeatureList = (currentPlan, newPlan) => {
  const currentPlanFeatures = ALL_FEATURES.filter(feature => feature.enabled.includes(currentPlan?.id));
  const newPlanFeatures = ALL_FEATURES.filter(feature => feature.enabled.includes(newPlan?.id));
  return currentPlanFeatures.filter(currentFeature =>
    !newPlanFeatures.some(newFeature => newFeature.label === currentFeature.label)
  );
}

const getPriceLabel = (plan, period) =>
  plan.prices[period] && `${plan.prices[period].currency || '$'} ${plan.prices[period].value}`

const checkPlanChange = (currentPlan, newPlan) => {
  const planOrder = {
    [PLANS.TINY.id]: 1,
    [PLANS.PRO.id]: 2,
    [PLANS.PRO_U.id]: 3,
  };

  if (planOrder[currentPlan] < planOrder[newPlan]) {
    return UPGRADE;
  } else if (planOrder[currentPlan] > planOrder[newPlan]) {
    return DOWNGRADE;
  } else {
    return CURRENT_PLAN;
  }
}

const PlanView = ({
                       isLoading,
                       currentPlanId,
                       defaultPlan,
                       periodProp,
                       onConfirm,
                       onDowngradeSubscription,
                       interval,
                       onPlanChange,
                       selectedPlan,
                     }) => {
  const [period, setPeriod] = useState(periodProp)
  const [planStage, setPlanStage] = useState(CURRENT_PLAN)
  const [downGradeList, setDownGradeList] = useState([])

  const activeInterval = interval === 'year' ? PERIOD_YEAR : PERIOD_MONTH

  useEffect(() => {
    setPlanStage(checkPlanChange(currentPlanId, selectedPlan.id))
    setDownGradeList(downGradeFeatureList(defaultPlan, selectedPlan))
  }, [selectedPlan])

  useEffect(() => {
    interval && setPeriod(activeInterval)
  }, [interval])

  const getCurrentStage = (stage) => {
    switch (stage) {
      case UPGRADE:
        return "You will get:"
      case DOWNGRADE:
        return "You will lose:"
      case CURRENT_PLAN:
        return 'Your current plan:'
      default:
        break;
    }
  }

  const onSubmit = (selectedPlan, period) => {
    if (planStage === DOWNGRADE) {
      onDowngradeSubscription(selectedPlan, period)
    } else {
      onConfirm({plan: `${getPriceLabel(selectedPlan, period)} ${period}`, period})
    }
  }

  return (
    <>
      <h4 className="mb-2 bold color-purple-2">
        Change plan to <PlanSelectionDropdown defaultPlanId={selectedPlan.id} onPlanSelected={onPlanChange}/>
      </h4>
      <div className="mt-3">{getCurrentStage(planStage)}</div>
      <FeatureList
        planId={selectedPlan.id}
        stage={planStage}
        list={downGradeList}
      />
      <div className="d-block">
        <div className="p-4 flex">
          {selectedPlan.prices.monthly && (
            <Button
              className="flex-grow-1"
              variant={period === PERIOD_MONTH ? 'outline-primary' : 'outline-secondary'}
              onClick={() => setPeriod(PERIOD_MONTH)}
            >
              <div className="price">
                <h6>Monthly</h6>
                <div>
                  {getPriceLabel(selectedPlan, PERIOD_MONTH)} <span className="price-period">/ month</span>
                </div>
              </div>
            </Button>
          )}
          {selectedPlan.prices.yearly && (
            <Button
              className="flex-grow-1 ms-2"
              variant={period === PERIOD_YEAR ? 'outline-primary' : 'outline-secondary'}
              onClick={() => setPeriod(PERIOD_YEAR)}
            >
              <div className="price">
                <h6>Yearly</h6>
                <div>
                  {getPriceLabel(selectedPlan, PERIOD_YEAR)} <span className="price-period">/ year</span>
                </div>
                {[PLANS.PRO.id, PLANS.PRO_U.id].includes(selectedPlan.id) && (
                  <div className="price-period mt-1">(2 months free)</div>
                )}
              </div>
            </Button>
          )}
        </div>
        {(planStage === CURRENT_PLAN) && (activeInterval === period) ? (
          <p className="bold">This is your current plan.</p>
        ) : (
          <p>
            You will be charged the difference now, then{' '}
            <b>
              {getPriceLabel(selectedPlan, period)}{' '}{period.toLowerCase()}
            </b>
            <br/>
            <span className="op-6">We will automatically charge your current payment method.</span>
          </p>
        )}
      </div>
      <div>
        <Button
          className="ms-auto me-auto mt-2 float-right"
          type="submit"
          disabled={isLoading || (activeInterval === period && currentPlanId === selectedPlan.id)}
          onClick={() => onSubmit(selectedPlan, period)}
        >
          {planStage === DOWNGRADE ? "Downgrade" : "Confirm"}
          {isLoading && <Spinner className="ms-2" animation="border" size="sm"/>}
        </Button>
      </div>
    </>
  )
}

export default PlanView