import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {Helmet} from "react-helmet";

import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { ErrorBox } from '../../components/ErrorBox/ErrorBox';
import { ERROR_MSGS, reCAPTCHA_CLIENT, errors } from '../../constants';
import SuccessBoxVerify from '../../components/SuccessBox/SuccessBoxVerify';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import HomepageUpload from '../../components/HomepageUpload';

import '../../App.css';
import './Home.css';
import '../../Animate.css';

export const STAGE_SUCCESS = 'success';
export const STAGE_SUCCESS_VERIFY = 'success_verify';
export const STAGE_INPUT = 'input';

const initialState = {
  stage: STAGE_INPUT,
  domain: undefined,
  showLoading: false,
  showError: false,
  errorMessage: undefined,
  recordedEmail: false
};

const contentTypes = {
  pdf: 'PDF',
  resume: 'resume',
  php: 'PHP project',
  'publish-poetry': 'publish poetry',
  'pdf-portfolio': 'PDF portfolio',
  'upload-floor-plan': 'floor plan',
  'pdf-to-qr-code': 'PDF'
};

const getPageType = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get('content');

  if (ref && localStorage) {
    localStorage.setItem('landing-page', ref)
  }

  return ref;
}

function getH1(contentType) {
  const type = contentTypes[contentType] || 'work online';
  return `The simplest way to host & share your ${type}`;
}

function getErrorOnClick(errorMessage, history) {
  if (ERROR_MSGS[errors.USER_EXISTS] === errorMessage) {
    return () => history.push('/login');
  }
  return undefined;
}

function getErrorCTA(errorMessage) {
  if (ERROR_MSGS[errors.USER_EXISTS] === errorMessage) {
    return 'Login';
  }
  return undefined;
}

export const LogoShowcase = ({ className }) => <div className={className}>
    <h3 className="logos-title">USED BY INDIVIDUALS, START-UPS & CORPORATES</h3>
    <div className="mt-3 opacity-75 logos">
      <img height="40" className="me-3 me-md-5" src="/assets/logos/salesforce.png" />
      <img height="25" className="m-auto op-7 me-3 me-md-5" src="/assets/logos/square.png" />
      <img height="20" className="m-auto op-7 me-3 me-md-5" src="/assets/logos/veed.svg" />
      <img height="25" className="m-auto op-6 me-3 me-md-5" src="/assets/logos/cognizant.png" />
      <img height="40" className="op-7" src="/assets/logos/nestle.png" />
    </div>
  </div>

class Home extends Component {
  onFieldChange = (field, value) => this.setState({ [field]: value });

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  hideOrDisplayView = () => {
    return {
      display: this.state.showLoading || this.state.showError ? 'none' : 'block'
    };
  };

  setRootState = (state) => this.setState(state);

  onClear = () => {
    this.setState(initialState);
  }

  componentDidMount() {
    setTimeout(() => document.addEventListener('mouseout', this.handleExit), 5000);
  }

  handleExit = (e) => {
    e = e ? e : window.event;
    // Get the current viewport width.
    const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (e.clientX >= vpWidth - 50) return;

    if (e.clientY >= 50) return;

    const from = e.relatedTarget || e.toElement;
    if (!from) {
      this.setState({
        showExit: true
      });
    }
  };

  render() {
    const contentType = getPageType();
    const { stage, domain, showUpdateSuccessView, showLoading, showError } = this.state;
    return (
      <>
        {contentType === 'pdf' && <Helmet>
          <link rel="canonical" href="https://tiiny.host/pdf-upload/" />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>}
        <div id="home" className="home mt-md-4 p-1">
          <div>
            <h1 className="title">{getH1(contentType)}</h1>
          </div>
          <div className="mt-5 middle-row">
            <div className="landing-left d-none d-xl-block text-end">
              <div className="landing-testimonial">
                <p className="font-20">
                  "I can’t believe that trillion dollar companies can’t produce software half as good as yours."
                </p>
                <span className="text-end mt-3 font-small"><img className="round-20 me-2" height={16} src={'assets/img/quote-auth-7.jpg'} alt=""/> Peter L.</span>
                <span className="text-end font-small d-block grey">Designer</span>
              </div>
            </div>
            <div className="upload-container">
              <div className="upload-box">
                {stage === STAGE_INPUT && (
                  <div style={this.hideOrDisplayView()}>
                    <HomepageUpload
                      setRootState={this.setRootState}
                    />
                  </div>
                )}
                {showLoading && <LoadingBox />}
                {stage === STAGE_SUCCESS && (
                  <div style={this.hideOrDisplayView()}>
                    <SuccessBox
                      domain={domain}
                      onFieldChange={this.onFieldChange}
                      showUpdateSuccessView={showUpdateSuccessView}
                      setRootState={this.setRootState}
                      onClose={this.onClear}
                      recordedEmail={this.state.recordedEmail}
                    />
                  </div>
                )}
                {stage === STAGE_SUCCESS_VERIFY && (
                  <div style={this.hideOrDisplayView()}>
                    <SuccessBoxVerify
                      domain={domain}
                      setParentState={this.onFieldChange}
                      showUpdateSuccessView={showUpdateSuccessView}
                      setRootState={this.setRootState}
                      onClose={this.onClear}
                      recordedEmail={this.state.recordedEmail}
                    />
                  </div>
                )}
                {showError && (
                  <ErrorBox
                    setRootState={this.setRootState}
                    message={this.state.errorMessage}
                    onClick={getErrorOnClick(this.state.errorMessage, this.props.history)}
                    ctaLabel={getErrorCTA(this.state.errorMessage)}
                  />
                )}
              </div>
              <div className="recaptcha-terms">
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
            </div>
            <div className="arrow-cta d-none d-xl-block">
              <div>Try for free</div>
              <img className="arrow" height="90" src="/assets/icons/arrow.svg" />
            </div>
          </div>
          <LogoShowcase className="mt-5" />
        </div>
      </>
    );
  }
}

export default withRouter(Home);
