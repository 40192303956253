import React, { useState } from 'react'
import { Nav, Navbar, NavDropdown, Modal } from 'react-bootstrap'
import { withRouter } from 'react-router'
import Sticky from 'react-stickynode'
import { connect } from 'react-redux'

import { accountModal, showUpgradeModal, toggleTeamModal, toggleSignUpModal } from '../../actions'
import { PLANS, PLANS_ID } from '../../../constants/plans'
import CTAButton from '../../../components/CTAButton/CTAButton'
import SignUpPage from '../../../Login/SignUpModal'
import { openPricingPopup } from '../../../utils/sendy'
import { resetUserProfile } from '../../actions'
import IntegrationsDropdown from "./components/IntegrationsDropdown";

import styles from './Header.module.css'
import './general.css'

const logout = (history, dispatch) => {
  localStorage.removeItem('token')
  dispatch(resetUserProfile())
  history.push('/login')
}

export const NavItem = ({ icon, alt, label, href, target, onClick, className }) => {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault() // Prevent default link behavior if onClick is provided
      onClick()
    }
  }

  return (
    <a
      className={styles.contentBoxNav}
      href={onClick ? '#' : href} // Fallback to "#" if onClick is used
      target={target}
      onClick={handleClick}
    >
      <img src={`/assets/icons/${icon}`} alt={alt} className={styles.img} />
      <span className={`${styles.contentText} ${className}`}>{label}</span>
    </a>
  )
}

export const Item = ({ icon, alt, label, href, target, onClick, className }) => {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault() // Prevent default link behavior if onClick is provided
      onClick()
    }
  }

  return (
    <a
      className={styles.contentBox}
      href={onClick ? '#' : href} // Fallback to "#" if onClick is used
      target={target}
      onClick={handleClick}
    >
      <img src={`/assets/icons/${icon}`} alt={alt} className={styles.img} />
      <span className={`${styles.contentText} ${className}`}>{label}</span>
    </a>
  )
}

const UpgradeButton = ({ dispatch }) => (
  <CTAButton
    className={`m-auto font-16 ${styles.button}`}
    label="Upgrade"
    onClick={() => {
      dispatch(showUpgradeModal(true))
      openPricingPopup()
    }}
    size="small"
    variant="black"
    img="left-arrow.svg"
  />
)

const getAccountButton = (trialAccount, isChildAccount, dispatch, onAddTeam) => {
  if (trialAccount) {
    return <UpgradeButton dispatch={dispatch} />
  } else if (isChildAccount) {
    return null
  } else {
    return null
  }
}

const HomeHeader = ({
  dispatch,
  history,
  proMode,
  productId,
  isLoading,
  isChildAccount,
  showUpgradePayModal,
  showDeleteAccountModal,
  showSignUpModal
}) => {
  const [sticky, setSticky] = useState(false)

  const isFreeAccount = !productId

  const handleStateChange = (status) => {
    setSticky(status.status === Sticky.STATUS_FIXED)
  }

  const planLabel = PLANS_ID[productId]?.label

  const onAddTeam = () => {
    if (productId === PLANS.PRO_U.id) {
      dispatch(toggleTeamModal(true))
    } else {
      showUpgradePayModal()
    }
  }

  return (
    <Sticky innerClass={sticky ? 'sticky' : ''} onStateChange={handleStateChange} innerZ={999}>
      <Modal
        show={showSignUpModal}
        onHide={() => dispatch(toggleSignUpModal(false))}
        centered
      >
        <div className="container p-3 ">
          <SignUpPage />
        </div>
      </Modal>
      <Navbar bg="white" expand="lg" collapseOnSelect>
        <Navbar.Toggle className="ms-3" aria-controls="responsive-navbar-nav" />
        <Navbar.Brand className="d-l-none d-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        <Navbar.Brand className="d-none d-l-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          {proMode && (
            <>
              <Nav className="me-auto" activeKey="/none">
                <Nav.Item>
                  <Nav.Link className="header-link float-left" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
                    <NavItem
                      icon="support.svg"
                      alt="support"
                      label="Support"
                      href="https://tiiny.host/mac/plugin.dmg"
                      target="_blank"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="header-link float-left" target="_blank" href="https://helpdesk.tiiny.host/">
                    <NavItem
                      icon="help-desk.svg"
                      alt="support"
                      label="Help Desk"
                      href="https://helpdesk.tiiny.host/"
                      target="_blank"
                    />
                  </Nav.Link>
                </Nav.Item>
                <IntegrationsDropdown />

                {!isChildAccount && (
                  <>
                    <Nav.Item className="d-lg-none d-block">
                      <Nav.Link className="header-link float-left">
                        <NavItem
                          icon="account.svg"
                          alt="manage"
                          label="Manage Account"
                          target="_blank"
                          onClick={() => dispatch(accountModal(true))}
                        />
                      </Nav.Link>
                    </Nav.Item>
                    {isFreeAccount && (
                      <Nav.Item className="d-lg-none d-block">
                        <Nav.Link
                          className="header-link float-left"
                        >
                          <NavItem
                            icon="upgrade.svg"
                            alt="manage"
                            label="Upgrade Subscription"
                            target="_blank"
                            onClick={onAddTeam}
                            className={styles.colorText}
                          />
                        </Nav.Link>
                      </Nav.Item>
                    )}

                    <Nav.Item className="d-lg-none d-block">
                      <Nav.Link className="header-link float-left">
                        <NavItem icon="team.svg" alt="manage" label="My Team" target="_blank" onClick={onAddTeam} />
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}

                {isFreeAccount && (
                  <Nav.Item className="d-lg-none d-block">
                    <Nav.Link className="header-link float-left">
                      <NavItem
                        icon="delete.svg"
                        alt="delete"
                        label="Delete account"
                        target="_blank"
                        onClick={showDeleteAccountModal}
                      />
                    </Nav.Link>
                  </Nav.Item>
                )}

                <Nav.Item className="d-lg-none d-block">
                  <Nav.Link className="header-link float-left">
                    <NavItem
                      icon="affiliate.svg"
                      alt="copylink"
                      label="Affiliate Programme"
                      target="_blank"
                      onClick={() => window.open('https://tiiny.host/affiliates', '_blank')}
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-lg-none d-block">
                  <Nav.Link className="header-link float-left">
                    <NavItem
                      icon="logout.svg"
                      alt="logout"
                      label="Sign out"
                      target="_blank"
                      onClick={() => logout(history, dispatch)}
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav className="account-tab d-none d-lg-flex">
                <NavDropdown
                  className={`${styles.accountBtn} p-md-0 m-auto`}
                  title={
                    <span className="d-flex justify-content-between align-items-center px-1 font-16">
                      <img
                        src="/assets/icons/account.svg"
                        className="me-2 rounded-circle dropdown-toggle"
                        height={25}
                        width={25}
                        alt="account"
                      />
                      {planLabel ? planLabel : 'Free'} plan
                      <img src={`/assets/icons/vector.svg`} className={styles.img_account} />
                    </span>
                  }
                >
                  <div className={styles.menu}>
                    {!isChildAccount && (
                      <>
                        <div className={styles.contentContainer}>
                          <Item
                            icon="account.svg"
                            alt="manage"
                            label="Manage Account"
                            target="_blank"
                            onClick={() => dispatch(accountModal(true))}
                          />
                          {isFreeAccount && (
                            <Item
                              icon="upgrade.svg"
                              alt="manage"
                              label="Upgrade Subscription"
                              target="_blank"
                              onClick={onAddTeam}
                              className={styles.colorText}
                            />
                          )}
                        </div>

                        <div className={styles.contentContainer}>
                          <Item icon="team.svg" alt="manage" label="My Team" target="_blank" onClick={onAddTeam}/>
                        </div>
                      </>
                    )}

                    {isFreeAccount && (
                      <div className={styles.contentContainer}>
                        <Item
                          icon="delete.svg"
                          alt="delete"
                          label="Delete account"
                          target="_blank"
                          onClick={showDeleteAccountModal}
                        />
                      </div>
                    )}
                    <div className={styles.contentContainer}>
                      <Item
                        icon="coin.svg"
                        alt="copylink"
                        label="Affiliate Programme"
                        target="_blank"
                        onClick={() => window.open('https://tiiny.host/affiliates', '_blank')}
                      />
                      <Item
                        icon="logout.svg"
                        alt="logout"
                        label="Sign out"
                        target="_blank"
                        onClick={() => logout(history, dispatch)}
                      />
                    </div>
                  </div>
                </NavDropdown>
                {!isLoading && (
                  <Nav.Link>
                    {getAccountButton(isFreeAccount, isChildAccount, dispatch, onAddTeam)}
                  </Nav.Link>
                )}
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </Sticky>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = (state) => ({
  productId: state.manage.userProfile.productId,
  isLoading: !state.manage.userProfile.email,
  isChildAccount: !!state.manage.userProfile?.childAccount,
  email: state.manage.userProfile.email,
  showSignUpModal: state.manage.showSignUpModal
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeHeader))
