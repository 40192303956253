import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {getAreaCurrency, TIMEZONE} from "../../../../constants/pricing/currency";

const UPIPay = () => {
  const isIndia = getAreaCurrency(TIMEZONE) === '₹';

  if (!isIndia)
    return null;

  const onClick = () => {
    window.fathom?.trackEvent('UPI_PAY_CLICK');
    alert('Sorry not available, please use card payment method instead');
  }

  return <Row className="mb-3">
    <Col>
      <Button className="tr-upi-pay w-100" variant="outline-dark" onClick={onClick}>
        Pay with <img src="assets/icons/upi-logo.webp" height={30} /> / <img src="assets/icons/paytm-logo.png" height={20} />
      </Button>
    </Col>
  </Row>
}

export default UPIPay