import React from 'react'
import { Button } from 'react-bootstrap'

import { QUARTERLY } from '../../../constants/plans/pro-student'
import CustomTooltip from '../../CustomTooltip'
import { PERIOD_YEAR } from '../../../constants/plans'
import { formatNumber, pluralize } from '../../../utils/general'

import styles from './PlanCard.module.css'

const getPrice = (period, planPrice) => (period === 'yearly' ? planPrice?.yearly.value / 12 : planPrice?.monthly?.value)

export const ToolTip = ({ children, text }) => <CustomTooltip label={text}>{children}</CustomTooltip>

const PlanCard = ({
  position,
  plan,
  planPrice,
  title,
  subtitle,
  period,
  features,
  ctaTheme,
  onSubPlanClick,
  popular
}) => {
  // const price = plan.prices[period].value
  const price = period === 'yearly' ? planPrice?.yearly?.value : planPrice?.monthly?.value
  const currency = period === 'yearly' ? planPrice?.yearly?.currency : planPrice?.monthly?.currency

  return (
    <div
      className={`${styles[position]} ${styles.container} flip-in-ver-left ${popular ? styles.popular : ''}`}
      onClick={() => onSubPlanClick(plan?.id)}
    >
      {popular && <div className={styles.popularBadge}>Most popular</div>}
      <div className={`${styles.headerContainer}`}>
        <h2 className={`font-26 font-weight-900 text-${ctaTheme} bold mt-3`}>{title}</h2>
        <div className={`${styles.price}`}>
          {price === 0 ? (
            <div>
              <div className="flex">
                <span className="font-26 font-weight-900 grey bold">
                  {currency}
                  <b className="font-47 bold">{formatNumber(getPrice(period, planPrice))}</b>

                  <span className={`${styles.period} black ms-2`}>
                    {' '}
                    / {period === QUARTERLY ? '3-months' : 'month'}
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex">
                <span className={`font-26 font-weight-900 text-${ctaTheme} bold`}>
                  {currency}
                  <b className={`font-47 text-${ctaTheme} bold`}>{formatNumber(getPrice(period, planPrice))}</b>
                  <span className={`${styles.period} black ms-2`}>/ {period === QUARTERLY ? '3-months' : 'month'}</span>
                </span>
              </div>
              {period === PERIOD_YEAR && (
                <div className="font-small grey italic text-start">
                  <i>(Billed {currency}{price} /year)</i>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`indie-flower-font font-18 mt-3 mb-3 text-start ${styles.subtitle}`}>{subtitle}</div>
      </div>
      <div>
        <div className={`flex pb-1`}>
          <img className="mt-1" height="18px" width="18px" alt="check" src={`/assets/icons/tick.png`} />
          <ToolTip text="abc.tiiny.site is one active project, multiple files can be uploaded on one active project">
            <div className={`dark-grey bold text-start ${styles.label}`}>
              {plan.projectLimit} active {pluralize('project', 'projects', plan.projectLimit)}
            </div>
          </ToolTip>
        </div>
        <div className="flex mt-2">
          <img className="mt-1" height="18px" width="18px" alt="check" src={`/assets/icons/tick.png`} />
          <ToolTip text="Limit per file upload, per site">
            <div className={`dark-grey bold text-start ${styles.label}`}>
              {formatNumber(plan.fileSizeLimit)} mb file uploads
              <br />
              {plan?.label === 'Free' && <span className="font-small fw-normal">(0.5mb PDF limit)</span>}
            </div>
          </ToolTip>
        </div>
        <div className="flex mt-2">
          <img className="mt-1" height="18px" width="18px" alt="check" src={`/assets/icons/tick.png`} />
          <ToolTip text="Limit per month, per account">
            <div className={`dark-grey bold text-start ${styles.label}`}>
              {formatNumber(plan.visitorLimit)} visitors <span className="font-small">/mo</span>
            </div>
          </ToolTip>
        </div>
      </div>
      {features.map(({ label, tooltip, enabled, featured }, index) => (
        <div className="mt-2" key={index}>
          {featured && (
            <ToolTip text={tooltip}>
              <div className={`flex ${enabled ? '' : styles.disabled}`}>
                <img
                  className="mt-1"
                  height="18px"
                  width="18px"
                  alt="check"
                  src={`/assets/icons/${enabled ? 'tick' : 'x'}.png`}
                />
                <div className={`dark-grey bold text-start ${styles.label}`}>{label}</div>
              </div>
            </ToolTip>
          )}
        </div>
      ))}
      <div>
        <Button className={`mt-3 ${styles.ctaButton}`} variant={ctaTheme}>
          Get {plan.label}
        </Button>
      </div>
    </div>
  )
}

export default PlanCard
